import { createContext, FC, ReactNode, useContext } from 'react'
import { ProcessType } from 'src/core/Shared/domain/Analytics'
import { ApplicationMode } from 'src/core/Shared/domain/ApplicationMode.model'

const defaultState = {
  mode: 'funnel' as ApplicationMode,
  analyticsProcessType: 'reservation' as ProcessType,
}

export const ApplicationModeContext = createContext<{
  mode: ApplicationMode
  analyticsProcessType: ProcessType
}>(defaultState)

interface Props {
  mode: ApplicationMode
  children: ReactNode
}

export const ApplicationModeProvider: FC<Props> = ({ mode, children }) => {
  return (
    <ApplicationModeContext.Provider
      value={{
        mode,
        analyticsProcessType:
          mode === 'management' ? 'modification' : 'reservation',
      }}
    >
      {children}
    </ApplicationModeContext.Provider>
  )
}

export const useApplicationMode = () => useContext(ApplicationModeContext)
